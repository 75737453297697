import cx from "clsx";
import { Button, Text, rem } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { useTranslation } from "next-i18next";
import classes from "./see-full-citation.module.css";

interface SeeFullCitationProps {
  onClick: () => void;
  opened: boolean;
}

export const SeeFullCitation = ({ onClick, opened }: SeeFullCitationProps) => {
  const { t } = useTranslation(["common"]);

  return (
    <>
      <Button onClick={onClick} size="compact-sm" variant="subtle" color="gray">
        <Text pr={rem(6)} inherit className={classes.text}>
          {t("see-full-citation")}
        </Text>
        {/* <Box
          className={cx(classes.downTriangle, { "data-opened": opened })}
          pb={rem(1.5)}
        /> */}
        <IconChevronDown
          size="1rem"
          className={cx(classes.chevron, { [classes.chevronRotate]: opened })}
        />
      </Button>
    </>
  );
};
