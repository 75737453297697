import { DatatableHeaderData } from "@components/ui";
import { Table, Text } from "@mantine/core";

export const HomeTableHeader = ({ id, value, type }: DatatableHeaderData) => {
  // Regular white text for plain text
  if (id === "label" || id === "goal-2025" || id === "goal-2030")
    return <Table.Th style={{ color: "white" }}>{value}</Table.Th>;

  let annotation = "";
  if (type === "preliminaryForYear") annotation = "†";
  if (type === "preliminaryForQuarter") annotation = "‡";

  return (
    <Table.Th style={{ color: "white" }}>
      <Text inherit span fs={type === "provisional" ? "unset" : "italic"}>
        {value}
      </Text>
      <Text inherit span fz="md">
        {annotation}
      </Text>
    </Table.Th>
  );
};
