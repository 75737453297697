import { Stratification } from "@api/case-counts";

const getStratificationLabel = (strat: Stratification, property: string) => {
  if (!property.endsWith("Id")) return "";

  if (property.includes("disease") || property.includes("area")) return "";

  if (strat[`${property}`] === 0) return "";

  const propertyName = `${property.slice(0, -2)}DisplayName`;

  return strat[propertyName] === "" ? "" : `, ${strat[propertyName]}`;
};

export const getDataLabel = (strat: Stratification) =>
  `${strat.fullAreaDisplayName}${Object.keys(strat)
    .map((x) => getStratificationLabel(strat, x))
    .join("")}`;
