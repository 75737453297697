import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "enums";
import { caseCountsUpdateHistoryApi } from "../axios";
import { CaseCountsUpdateHistory } from "../case-counts-update-history.type";

const getCaseCountsUpdateHistoryLatest = async () =>
  (await caseCountsUpdateHistoryApi.get<CaseCountsUpdateHistory[]>("/latest"))
    .data;

export const useCaseCountsUpdateHistoryLatest = () =>
  useQuery(
    [QueryKeys.CaseCountsUpdateHistoryLatest],
    getCaseCountsUpdateHistoryLatest
  );
