import { List, ListProps } from "@mantine/core";
import React, { FC, PropsWithChildren } from "react";
import { useComponents } from "@hooks/use-components";
import { Item, ItemProps } from "./item";
import classes from "./citation-list.module.css";

export interface CitationListProps
  extends PropsWithChildren,
    Omit<ListProps, "children"> {}
export interface CitationListFC extends FC<CitationListProps> {
  Item: FC<ItemProps>;
}

export const CitationList: CitationListFC = ({ children, ...rest }) => {
  const Items = useComponents(children, Item);

  return (
    <List
      type="ordered"
      spacing="sm"
      mb="sm"
      classNames={{ itemWrapper: classes.itemWrapper }}
      {...rest}
    >
      {Items}
    </List>
  );
};

CitationList.Item = Item;
