import { Goal } from "@api/case-counts";
import { MeasureType } from "@icf-corp/hivgov-common-ui/line-chart/models";

export interface GetGoalValueByMeasureOptions {
  includeNote?: boolean;
}

export const getGoalValueByMeasure = (
  goal: Pick<Goal, "value" | "percentage" | "note"> | undefined,
  measure: MeasureType,
  options?: GetGoalValueByMeasureOptions
) => {
  if (!goal) return undefined;

  const attr = measure === ("count" as MeasureType) ? "value" : "percentage";
  const value = goal[attr];

  if (value === "" || value === undefined || value === null) return undefined;

  if (options?.includeNote) {
    return goal.note ? `${Number(value)}§` : Number(value);
  }

  return Number(value);
};
