import { CaseCounts } from "@api/case-counts";

export const sortCaseCountsStrats = (
  caseCounts: CaseCounts,
  sortAttr: string | undefined
) =>
  caseCounts.strats
    .map((strat, i) => ({ strat, i }))
    .sort(({ strat: a }, { strat: b }) =>
      sortAttr ? a[sortAttr].localeCompare(b[sortAttr]) : 0
    );
