import { IndicatorYear } from "@api/diseases";
import { YearSpan } from "@icf-corp/hivgov-common-ui/line-chart/models";

export const selectChartPrelimYears = (rawData: IndicatorYear[]): YearSpan => {
  const years = rawData.map(
    ({ year: { year }, indicatorYearDataType: { type } }) => ({
      year,
      type,
    })
  );

  const startYear = years.find(({ type }) => type !== "provisional")?.year;
  const endYear = startYear
    ? years.findLast(({ type }) => type !== "provisional")?.year
    : undefined;

  return { startYear, endYear } as YearSpan;
};
