import { Data } from "@api/case-counts";
import { MeasureType } from "@icf-corp/hivgov-common-ui/line-chart/models";

export const getCIByMeasure = (
  data: (Data | undefined)[],
  type: "lower" | "upper",
  measure: MeasureType
) =>
  data.map((x) => {
    if (!x) return null;

    if (measure === ("count" as MeasureType)) {
      return type === "lower" ? x.caseLci : x.caseUci;
    }

    return type === "lower" ? x.lci : x.uci;
  });
