import { CaseCounts } from "@api/case-counts";
import { getDataLabel } from "functions/chart";
import { sortCaseCountsStrats } from "..";

export const selectLabelsFromCaseCounts = (
  caseCounts: CaseCounts,
  sortAttr: string | undefined
) =>
  sortCaseCountsStrats(caseCounts, sortAttr).map(({ strat }) =>
    getDataLabel(strat)
  );
