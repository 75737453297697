import * as XLSX from "xlsx/xlsx.mjs";
import { SDOHData } from "@api/sdoh";
import { SDOHInfo } from "@hooks/use-sdoh-data/use-sdoh-data";
import { IndicatorData } from "@hooks/use-indicator-data";
import { CaseCounts, DataType } from "@api/case-counts";
import { GeoPageTableResult } from "@hooks/geographic-page";
import { IndicatorYear } from "@api/diseases";
import {
  getCaseCountsDataByYear,
  getCaseCountsGoalsByYear,
  getGoalsFromData,
  getValuesFromData,
  selectYearsWithPrelimInfo,
} from "@functions/data";
import { MeasureType } from "@icf-corp/hivgov-common-ui/line-chart/models";

export interface indicatorDataWithLatestProvisional
  extends Pick<IndicatorData, "displayName" | "defaultMeasure"> {
  latestProvisional:
    | {
        latestValue: number | undefined;
        latestYear: string | undefined;
        goal: number | undefined;
      }
    | undefined;
}
export interface indicatorDataWithGeoTable
  extends Pick<IndicatorData, "displayName" | "defaultMeasure"> {
  geoTable: GeoPageTableResult;
}

export const downloadGeographicActiveNational = (
  sdohData: SDOHData,
  sdohInfo: SDOHInfo[],
  indicatorData: IndicatorData[],
  indicatorCaseCounts: CaseCounts
) => {
  // SDOH sheet
  const headers = [
    "Label",
    "Years",
    ...sdohInfo.map((sdoh) => sdoh.displayName),
  ];

  const row2021 = [
    "National",
    2021,
    ...sdohInfo.map((sdoh) =>
      sdoh.year === 2021 ? +parseFloat(sdohData[sdoh.name]).toFixed(2) : ""
    ),
  ];

  const sdohArray = [headers, row2021];

  // Indicators sheet
  const indicatorHeaders = ["Indicator", "Type", "Latest Year", "Value"];

  const rows = indicatorCaseCounts.strats.map(
    ({ diseaseId, diseaseDisplayName }, i) => {
      const rowData = indicatorCaseCounts.data[i];
      const indicatorInfo = indicatorData.find(({ id }) => id === diseaseId);

      const measureType = indicatorInfo?.defaultMeasure ?? "percentage";
      const latestData = rowData.data.findLast(
        ({ type }) => type === "provisional"
      );
      const latestValue =
        measureType === "percentage" ? latestData?.rate : latestData?.cases;

      return [
        diseaseDisplayName,
        measureType,
        latestData?.year.year ?? "---",
        latestValue,
      ];
    }
  );

  const indicatorArray = [indicatorHeaders, ...rows];

  const workbook = XLSX.utils.book_new();
  const sdohSheet = XLSX.utils.aoa_to_sheet(sdohArray);
  const indicatorSheet = XLSX.utils.aoa_to_sheet(indicatorArray);
  XLSX.utils.book_append_sheet(workbook, sdohSheet, "National_SDOH");
  XLSX.utils.book_append_sheet(workbook, indicatorSheet, "National_Indicators");
  XLSX.writeFile(workbook, "National_Data.xlsx");
};

export const formatHeader = (heading: {
  id: number;
  value: string;
  type: DataType;
}) => {
  if (heading.type === "preliminaryForQuarter") {
    return `${heading.value} Prelim For Quarter`;
  }
  if (heading.type === "preliminaryForYear") {
    return `${heading.value} Prelim`;
  }
  return parseInt(heading.value, 10);
};

export const downloadGeographicActiveNonNational = (
  sdohData: SDOHData,
  sdohInfo: SDOHInfo[],
  indicatorData: IndicatorData[],
  allIndicatorYears: IndicatorYear[][],
  indicatorCaseCounts: CaseCounts,
  areaName: string | undefined
) => {
  const workbook = XLSX.utils.book_new();

  // SDOH sheet
  const headers = [
    "Label",
    "Years",
    ...sdohInfo.map((sdoh) => sdoh.displayName),
  ];

  const row2021 = [
    areaName,
    2021,
    ...sdohInfo.map((sdoh) =>
      sdoh.year === 2021 ? +parseFloat(sdohData[sdoh.name]).toFixed(2) : ""
    ),
  ];

  const sdohArray = [headers, row2021];
  const sdohSheet = XLSX.utils.aoa_to_sheet(sdohArray);
  XLSX.utils.book_append_sheet(workbook, sdohSheet, "SDOH");

  // Indicator Sheets
  allIndicatorYears.forEach((yearsArr) => {
    const yearsWithPrelimInfo = selectYearsWithPrelimInfo(yearsArr);
    const indicatorHeaders = [
      "Label",
      "Type",
      ...yearsWithPrelimInfo,
      "2025 - Goal",
      "2030 - Goal",
    ];

    const indicatorId = yearsArr[0].diseaseId;
    const { displayName, defaultMeasure } = indicatorData.find(
      ({ id }) => id === indicatorId
    )!;
    const indicatorIndex = indicatorCaseCounts.strats.findIndex(
      (s) => s.diseaseId === indicatorId
    );
    const { data, goals } = indicatorCaseCounts.data[indicatorIndex];
    const dataByYears = getValuesFromData(
      getCaseCountsDataByYear(
        data,
        yearsArr.map(({ year }) => year.year)
      ),
      defaultMeasure as MeasureType
    ).map((x) => (x === undefined ? "---" : x));
    const goalsByYear = getGoalsFromData(
      getCaseCountsGoalsByYear(goals),
      defaultMeasure as MeasureType
    ).map((x) => (x === undefined ? "---" : x));

    const row = [areaName, defaultMeasure, ...dataByYears, ...goalsByYear];

    const indicatorArray = [indicatorHeaders, row];
    const sheet = XLSX.utils.aoa_to_sheet(indicatorArray);
    XLSX.utils.book_append_sheet(
      workbook,
      sheet,
      displayName.replace(" ", "-")
    );
  });

  XLSX.writeFile(workbook, "Jurisdiction_Data.xlsx");
};

export const handleDownloadAllData = () => {
  const link = document.createElement("a");
  link.href = `${process.env.FILES_URL}/AHEAD_data_all_latest.xlsx`;
  link.click();
};
