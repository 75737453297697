import { CaseCounts } from "@api/case-counts";
import { MantineTheme } from "@mantine/core";
import { getLineColor, startingColorIndex } from "functions/chart";
import { sortCaseCountsStrats } from "..";

export const selectColorsFromCaseCounts = (
  caseCounts: CaseCounts,
  theme: MantineTheme,
  colorScheme: "light" | "dark",
  sortAttr: string | undefined,
  activeConfidenceIntervals: number[]
) =>
  sortCaseCountsStrats(caseCounts, sortAttr).reduce(
    (acc: string[], { strat, i }) => {
      const colorIndex =
        // TODO There is an issue with the datatypes here, they are actually numeric but mistyped...
        // eslint-disable-next-line eqeqeq
        (caseCounts.filters.areaId?.some((a) => a != "0")
          ? 0
          : startingColorIndex[strat.diseaseId]) + i;
      if (activeConfidenceIntervals.includes(i)) {
        acc.push(getLineColor(theme, colorScheme, colorIndex));
        acc.push(getLineColor(theme, colorScheme, colorIndex));
        acc.push(getLineColor(theme, colorScheme, colorIndex));
      } else {
        acc.push(getLineColor(theme, colorScheme, colorIndex));
      }
      return acc;
    },
    []
  );
