import { IndicatorYear } from "@api/diseases";

export const selectYearsWithPrelimInfo = (rawData: IndicatorYear[]) =>
  rawData.map(
    ({ year: { year }, indicatorYearDataType: { type }, quarterMonth }) =>
      `${year}${type === "preliminaryForYear" ? " - Preliminary" : ""}${
        type === "preliminaryForQuarter"
          ? ` - Preliminary${quarterMonth ? ` as of ${quarterMonth}` : ""}`
          : ""
      }`
  );
