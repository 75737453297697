import { FC, PropsWithChildren } from "react";
import { List } from "@mantine/core";
import { useComponents } from "@hooks/use-components";
import { SeeFullCitation } from "@components/ui/see-full-citation";
import { useDisclosure } from "@mantine/hooks";
import { FullCitation } from "@components/ui/see-full-citation/full-citation";
import { Preview, PreviewProps } from "./preview";
import { Citation, CitationProps } from "./citation";

export interface ItemProps extends PropsWithChildren {
  fullCitationText?: string;
}

export interface ItemFC extends FC<ItemProps> {
  Preview: FC<PreviewProps>;
  Citation: FC<CitationProps>;
}

export const Item: ItemFC = ({ children, fullCitationText }) => {
  const [PreviewText] = useComponents(children, Preview);
  const [CitationText] = useComponents(children, Citation);
  const [opened, handlers] = useDisclosure(false);

  return (
    <>
      <List.Item>
        {PreviewText}{" "}
        {fullCitationText && (
          <SeeFullCitation onClick={handlers.toggle} opened={opened} />
        )}
      </List.Item>
      {fullCitationText && (
        <FullCitation opened={opened} textToCopy={fullCitationText}>
          {CitationText}
        </FullCitation>
      )}
    </>
  );
};
Item.displayName = "Item";
Item.Preview = Preview;
Item.Citation = Citation;
