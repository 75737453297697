import { DataType } from "@api/case-counts";
import { IndicatorYear } from "@api/diseases";

export const selectPrelimIndices =
  (selectType: DataType) => (years: IndicatorYear[]) => {
    const indices = years.reduce<number[]>(
      (acc, { indicatorYearDataType: { type } }, j) =>
        type === selectType ? acc.concat(j) : acc,
      []
    );

    return indices.length === 0 ? undefined : indices;
  };
