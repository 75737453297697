import { IndicatorYear } from "@api/diseases";

/**
 * Maps indicator year data to table header object
 *
 * @param rawData IndicatorYear array
 * @param includeQuarterly
 * @returns
 */
export const selectTableYearHeaders = (rawData: IndicatorYear[]) =>
  rawData.map(
    ({ year: { year }, yearId, indicatorYearDataType: { type } }) => ({
      id: yearId,
      value: year === "2020" ? "2020*" : year,
      type,
    })
  );
