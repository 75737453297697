import { CitationList } from "@components/ui/citation-list";
import { Item } from "@components/ui/citation-list/item";
import { Diseases } from "@enums/diseases";
import { Anchor } from "@mantine/core";
import { useTranslation } from "next-i18next";
import links from "static/links.json";

export interface DataSourcesProps {
  id: number;
}

export const DataSources = ({ id }: DataSourcesProps) => {
  const key = Diseases[id];
  const { t } = useTranslation(["data-sources", "common"]);
  const dataTableFullCitation = t(
    `data-sources:data-table-citation.full-citation.${key}`
  );

  const reportFullCitation = t(
    `data-sources:report-citation.full-citation.${key}`
  );

  return (
    <CitationList my="md">
      <CitationList.Item fullCitationText={dataTableFullCitation}>
        <Item.Preview>
          <Anchor
            href={links["data-table-citation"][key]}
            td="underline"
            target="_blank"
          >
            {t(`data-sources:data-table-citation.preview.${key}`)}
          </Anchor>{" "}
          &mdash;
        </Item.Preview>
        <Item.Citation>{dataTableFullCitation}</Item.Citation>
      </CitationList.Item>
      <CitationList.Item fullCitationText={reportFullCitation}>
        <Item.Preview>
          <Anchor
            href={links["report-citation"][key]}
            td="underline"
            target="_blank"
          >
            {t(`data-sources:report-citation.preview.${key}`)}
          </Anchor>{" "}
          &mdash;
        </Item.Preview>
        <Item.Citation>{reportFullCitation}</Item.Citation>
      </CitationList.Item>
    </CitationList>
  );
};
