import { Button, Collapse, CopyButton, Flex, Text, rem } from "@mantine/core";
import { FC, PropsWithChildren } from "react";
import classes from "./full-citation.module.css";

interface FullCitationProps extends PropsWithChildren {
  opened: boolean;
  textToCopy: string;
}

export interface FullCitationFC extends FC<FullCitationProps> {}

export const FullCitation: FullCitationFC = ({
  children,
  opened,
  textToCopy,
}: FullCitationProps) => (
  <Collapse in={opened}>
    <Flex
      className={classes.fullCitation}
      justify="space-between"
      gap="xl"
      p="lg"
      mt={rem(7)}
    >
      <Text>{children}</Text>
      <CopyButton value={textToCopy} timeout={2000}>
        {({ copied, copy }) => (
          <Button onClick={copy} className={classes.button}>
            {copied ? "Copied" : "Copy"}
          </Button>
        )}
      </CopyButton>
    </Flex>
  </Collapse>
);
