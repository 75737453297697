import { IndicatorYear } from "@api/diseases";

export const filterYearsByQuarterly = (
  data: IndicatorYear[],
  includeQuarterly: boolean
) =>
  data.filter(
    ({ indicatorYearDataType: { type } }) =>
      includeQuarterly || type !== "preliminaryForQuarter"
  );
