import { chartColors } from "@constants";
import { Diseases } from "@enums/diseases";
import { MantineTheme, parseThemeColor } from "@mantine/core";

export const startingColorIndex = {
  [Diseases.Incidence]: 3,
  [Diseases.KnowledgeOfStatus]: 9,
  [Diseases.Diagnoses]: 4,
  [Diseases.LinkageToCare]: 1,
  [Diseases.ViralSuppression]: 0,
  [Diseases.PrEPCoverage]: 10,
};

export const getLineColor = (
  theme: MantineTheme,
  colorScheme: "light" | "dark",
  index: number
) =>
  parseThemeColor({
    color: chartColors[colorScheme][index % chartColors[colorScheme].length],
    colorScheme,
    theme,
  }).value;
