import { CaseCountsUpdateHistory } from "@api/case-counts-update-history";

export const formatDate = (data: CaseCountsUpdateHistory[] | undefined) => {
  let result = "";
  const date = data?.[0].date;
  if (date) {
    const year = date.substring(0, 4);
    const month = date.substring(5, 7);
    const day = date.substring(8);
    const DateObj = new Date(Number(year), Number(month) - 1, Number(day));
    result = DateObj.toLocaleDateString();
  }
  return result;
};
