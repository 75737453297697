import { Data } from "@api/case-counts";
import { MeasureType } from "@icf-corp/hivgov-common-ui/line-chart/models";

export const getDataValueByMeasure = (
  data: Pick<Data, "cases" | "rate"> | undefined,
  measure: MeasureType
) => {
  if (!data) return undefined;

  const attr = measure === ("count" as MeasureType) ? "cases" : "rate";
  const value = data[attr];

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (value === "" || value === null || value === undefined) return undefined;

  return Number(value);
};
