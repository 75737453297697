import { Goal } from "@api/case-counts";
import { getGoalValueByMeasure } from "@functions/data";
import {
  GoalDataPoint,
  MeasureType,
} from "@icf-corp/hivgov-common-ui/line-chart/models";

export const goalsToChartGoalDataPoints = (
  data: (Goal | undefined)[],
  measure: MeasureType
): GoalDataPoint[] | undefined => {
  // First, are there missing goals from api
  const missingGoals = data.some((g) => g === undefined);
  if (missingGoals) return undefined;

  // no missing goals, retrieve the correct values
  const points = data.map((x) => ({
    year: x!.year,
    value: getGoalValueByMeasure(x, measure)?.toString() ?? "",
  }));

  // if any values are zero
  const missingData = points.some((g) => g.value === "" || g.value === "0");

  return missingData ? undefined : points;
};
